import React, { useEffect, useRef } from "react";
import styles from "./slideBanner.module.css";
import AK from "../../assets/images/main/client-logo-ak.svg";
import MEPLZ from "../../assets/images/main/client-logo-meplz.svg";
import YGBS from "../../assets/images/main/client-logo-ygbs.svg";
import QUANTBOOK from "../../assets/images/main/client-logo-quantbook.svg";
import AVALVE from "../../assets/images/main/client-logo-avalve.svg";
import PINCAR from "../../assets/images/main/client-logo-pincar.svg";
import ANSWER from "../../assets/images/main/client-logo-answer.svg";
import TERAVERSE from "../../assets/images/main/client-logo-teraverse.svg";
import ROADSTAFF from "../../assets/images/main/client-logo-roadstaff.svg";
import MMX from "../../assets/images/main/client-logo-mmx.svg";
import YUAN from "../../assets/images/main/client-logo-yuan.svg";

export const section2_Banner = [
  {
    id: 0,
    img: YUAN,
  },
  {
    id: 1,
    img: AK,
  },
  {
    id: 2,
    img: MEPLZ,
  },
  {
    id: 3,
    img: YGBS,
  },
  {
    id: 4,
    img: QUANTBOOK,
  },
  {
    id: 5,
    img: AVALVE,
  },
  {
    id: 6,
    img: PINCAR,
  },
  {
    id: 7,
    img: ANSWER,
  },
  {
    id: 8,
    img: TERAVERSE,
  },
  {
    id: 9,
    img: ROADSTAFF,
  },
  {
    id: 10,
    img: MMX,
  },
];

const SlideBanner = () => {
  const bannerRef = useRef(null);
  const slideWrapRef = useRef(null);

  useEffect(() => {
    bannerRef.current.id = "roller1";
    const cloneBanner = bannerRef.current.cloneNode(true);
    cloneBanner.id = "roller2";
    slideWrapRef.current.appendChild(cloneBanner);
    document.querySelector("#roller1").style.left = "0px";
    document.querySelector("#roller2").style.left =
      document.querySelector("#roller1").offsetWidth + "px";
    bannerRef.current.classList.add("original");
    cloneBanner.classList.add("clone");
  }, []);

  return (
    <div className={styles.slide_wrap} ref={slideWrapRef}>
      <ul className={styles.section2_banner} ref={bannerRef}>
        {section2_Banner.map((list) => {
          return (
            <li key={list.id}>
              <img src={list.img} alt="Company logo" />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SlideBanner;
